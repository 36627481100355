import tokens from './tokens'
import { FarmConfig } from './types'

const farms: FarmConfig[] = [
  /**
   * These 3 farms (PID 0, 1, 2) should always be at the top of the file.
   */
  {
    pid: 0,
    lpSymbol: 'MEOW',
    lpAddresses: {
      97: '0xC58322eb9554e7927C1d08D93FC3aBdB0D3EdAb0',
      56: '0xb58609Ca9C91073d815067BDacD3CB2FC3Beb41F',
    },
    token: tokens.meow,
    quoteToken: tokens.meow,
  },
  {
    pid: 1,
    lpSymbol: 'MEOW-BNB LP',
    lpAddresses: {
      97: '0x02125089db0e25393AEdb27BE1B6b257B4994F62',
      56: '0x7101C5b98c0F999933C8C21505638BCFE0E0B1b0',
    },
    token: tokens.meow,
    quoteToken: tokens.wbnb,
  }, 
  {
    pid: 2,
    lpSymbol: 'MEOW-BUSD LP',
    lpAddresses: {
      97: '0xC9849ACC6B3C7bDdbC836F524a687774b4CE81b2',
      56: '0x2F60E814b90c26fC08eAA43f50c7EB4cb0DC8D67',
    },
    token: tokens.meow,
    quoteToken: tokens.busd,
  },
  {
    pid: 7,
    lpSymbol: 'BNB-BUSD LP',
    lpAddresses: {
      97: '0x58F876857a02D6762E0101bb5C46A8c1ED44Dc16',
      56: '0x6939c798C9925fd66644f7AC78f90bd25693d54a',
    },
    token: tokens.busd,
    quoteToken: tokens.wbnb,
  },
 
  {
    pid: 3,
    lpSymbol: 'MEOW-USDT LP',
    lpAddresses: {
      97: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
      56: '0x2E8806355D15e13eB5EEc2f77478501feE3a3c09',
    },
    token: tokens.meow,
    quoteToken: tokens.usdt,
  },
  {
    pid: 4,
    lpSymbol: 'WBNB',
    lpAddresses: {
      97: '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
      56: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
    },
    token: tokens.wbnb,
    quoteToken: tokens.busd,
  },
  {
    pid: 5,
    lpSymbol: 'USDT',
    lpAddresses: {
      97: '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
      56: '0x55d398326f99059fF775485246999027B3197955',
    },
    token: tokens.usdt,
    quoteToken: tokens.busd,
  },
  {
    pid: 6,
    lpSymbol: 'WETH',
    lpAddresses: {
      97: '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
      56: '0x2170Ed0880ac9A755fd29B2688956BD959F933F8',
    },
    token: tokens.weth,
    quoteToken: tokens.busd,
  },

]

export default farms
